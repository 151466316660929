.App {
    font-family: Roboto, Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  nav {
    background-color: #f8f9fa;
    padding: 10px 0;
    margin-bottom: 20px;
  }
  
  nav a {
    color: #007bff;
    text-decoration: none;
    margin-right: 15px;
  }
  
  nav a:hover {
    text-decoration: underline;
  }
  
  .post-list-item {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .post-list-item h2 {
    margin-top: 0;
    color: #343a40;
  }
  
  .post-list-item a {
    color: #007bff;
    text-decoration: none;
  }
  
  .post-list-item a:hover {
    text-decoration: underline;
  }